<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 21.306 17.5">
    <path
      :style="iconStyle"
      d="M4.072,23.1a.361.361,0,1,0,.45-.564,9.874,9.874,0,0,1-.832-.743L1.021,19.12l2.753-2.753A9.741,9.741,0,0,1,13.818,14.01a.361.361,0,0,0,.23-.684A10.459,10.459,0,0,0,3.263,15.857L0,19.12,3.18,22.3A10.652,10.652,0,0,0,4.072,23.1Z"
      transform="translate(0 -10.066)"
    />
    <path
      :style="iconStyle"
      d="M30.208,18.906a10.6,10.6,0,0,0-1.585-1.3.361.361,0,0,0-.4.6A9.865,9.865,0,0,1,29.7,19.416l2.669,2.669-2.753,2.753a9.836,9.836,0,0,1-10.23,2.293.361.361,0,0,0-.244.679,10.554,10.554,0,0,0,10.984-2.463l3.263-3.263Z"
      transform="translate(-12.081 -13.114)"
    />
    <path
      :style="iconStyle"
      d="M25.682,29.345a4.14,4.14,0,0,1-2.19-.624.361.361,0,0,0-.381.613,4.872,4.872,0,0,0,6.621-6.845.361.361,0,0,0-.6.4,4.15,4.15,0,0,1-3.45,6.455Z"
      transform="translate(-14.663 -16.175)"
    />
    <path
      :style="iconStyle"
      d="M21.909,17.5a4.143,4.143,0,0,1,2.113.577.361.361,0,0,0,.368-.621,4.873,4.873,0,0,0-6.856,6.337.361.361,0,0,0,.648-.318,4.11,4.11,0,0,1-.422-1.826A4.155,4.155,0,0,1,21.909,17.5Z"
      transform="translate(-10.89 -12.625)"
    />
    <path
      :style="iconStyle"
      d="M23.808,5.38a.36.36,0,0,0-.51,0L6.52,22.158a.361.361,0,1,0,.51.51L23.808,5.89A.36.36,0,0,0,23.808,5.38Z"
      transform="translate(-4.1 -5.274)"
    />
  </svg>
</template>

<script>
// TODO: This icon is not used anywhere
export default {
  props: {
    height: {
      type: String,
      default: '17.5',
    },
    width: {
      type: String,
      default: '21.306',
    },
    color: {
      type: String,
      default: 'menu',
    },
  },
  computed: {
    iconStyle: function () {
      return {
        fill: ['$' + this.color, `var(--${this.color})`],
      }
    },
  },
}
</script>
